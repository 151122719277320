import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Paidsearch_top from "../images/paid_search/paid_searchtop.png"
import Process__Step_Sr1 from "../images/paid_search/process1.png"
import Process__Step_Sr2 from "../images/paid_search/process2.png"
import Process__Step_Sr3 from "../images/paid_search/process3.png"
import Remarketing from "../images/paid_search/remarketing.jpg"
import Marketplace from "../images/paid_search/ssd.png"
import Digital_batch from "../images/Digital-partner.jpg"
import Ads from "../images/paid_search/ads.jpg"
import {Helmet} from "react-helmet"


export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            PPC Advertising & Management for B Corps & Purpose Driven Brands        </title>
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
              <link rel="canonical" href={this.state.url} />
              <meta name="description" content="Hire Certified Adwords Professionals to manage your Google Ads, Bing & other networks. We offer PPC services to a global audience & love working with Purpose driven companies & Sustainable brands." />
              <meta property="og:site_name" content="CueForGood" />
              <meta property="fb:app_id" content="289086684439915" />
              <meta property="og:url" content={this.state.url} />
              <meta property="og:title" content="PPC Advertising & Management for B Corps & Purpose Driven Brands" />
              <meta property="og:description" content="Hire Certified Adwords Professionals to manage your Google Ads, Bing & other networks. We offer PPC services to a global audience & love working with Purpose driven companies & Sustainable brands." />
              <meta property="og:image" content="https://www.cueforgood.com/images/cb.jpg" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:description" content="Hire Certified Adwords Professionals to manage your Google Ads, Bing & other networks. We offer PPC services to a global audience & love working with Purpose driven companies & Sustainable brands." />
              <meta name="twitter:title" content="PPC Advertising & Management for B Corps & Purpose Driven Brands" />
              <meta name="twitter:site" content="@CueForGood" />
              <meta name="twitter:image" content="https://www.cueforgood.com/images/cb.jpg" />
        </Helmet>
        <article className="static_page paid_search-page">
          <section className="ser_sec_row  creative_design_page ">
            <div className="container">
              <div className="ser_sec_row_lt wdt-60 pd_right_40">
                <h1 className="heading_main">Digital Advertising
                      <img
                  src={Digital_batch}
                  alt="Digital Advertising"
                  className="img-responsive digital-batch dig"
                />
                </h1>
                <div className="ser_sec_row_lt_text">
                  <p>
                    The world is online and so are the brands that meet the
                    dynamic demands of its consumers globally on a daily basis.
                    But, how a brand connects with its consumers in what moment
                    is pivotal for its growth. Since Digital Advertising means
                    paying-per-interaction for the brands, our team of
                    Google-certified professionals, with over a decade of
                    experience, values each interaction and the money spent on
                    it. <br />
                    <br />
                    Covering all the touchpoints of a consumer’s purchase
                    journey, we formulate a statistic-based strategy that gets
                    your business a bang for its buck.
                  </p>
                </div>
              </div>
              <div className="ser_sec_row_rt wdt-40 pd_left_40 text-center ">
                <img
                  src={Paidsearch_top}
                  alt="Digital Advertising"
                  className="img-responsive"
                />
              </div>
              <div className="border-light"> </div>
            </div>
          </section>

          <section className=" our-process">
            <div className="container">
              <div className="main-heading text-center">
                <h2 className="heading_main">our approach</h2>
              </div>
              <div className="process-steps">
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr1}
                        alt="CueForGood Process"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>who | where | when | how</h3>
                    <p>
                      In the eCommerce industry, knowing the touchpoints of your
                      consumers is of utmost importance. To get an in-depth
                      understanding of your business, we thoroughly evaluate the
                      who, where, when, and how of targeting the right audience
                      on the right platform at the right time.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr2}
                        alt="TARGET CONSUMERS WITH INTENT"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>target consumers with intent</h3>
                    <p>
                      With a comprehensive understanding of your brand, we begin
                      setting up the most suitable strategy by running a
                      detailed keyword search, targeting the right audience
                      based on their interests and thereby creating an engaging
                      ad that elicits a response to the Call-To-Action.
                    </p>
                  </div>
                </div>
                <div className="steps">
                  <div className="steps-inn">
                    <figure>
                      <img
                        src={Process__Step_Sr3}
                        alt="START SLOW, TEST & GROW"
                        className="image_responsive"
                      />
                    </figure>
                    <h3>start slow, test & grow</h3>
                    <p>
                      Assessing the results at each stage, we constantly check
                      for consistent growth by following up with the
                      ever-changing needs of your consumers, all the while
                      keeping the focus on the purchase intent of the consumer
                      which will lead to conversions and not just traffic.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics  content-both">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <h2 className="heading_main">
                    Paid Social
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      Social Media offers such a diverse real estate to promote all kinds of content, so much so that building brand awareness through paid social
                       media campaigns can eventually lead to a growth in revenue for your business.
                       To meet the dynamic demands of your consumers, we move past the 
                      ‘One-Size-Fits-All’ approach to create bespoke ads that engage the audience to click ‘Add to Cart’ for your products.
                      <br />
                      <br />
                       Write to us to understand if paid social media efforts can help your business.
                    </p>
                    <Link to="/contact/" className="btn main_cta">
                      Get in touch
                  </Link>
                  </div>

                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">
                    Paid Search
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      The attention span of consumers, nowadays, is quite
                      fickle. Therefore, your brand should be able to keep up
                      with what the consumers are searching for while
                      maintaining an active and stable presence in the online
                      world. With the help of Google/ Bing Text & Shopping ads,
                      we target the touchpoints of your consumers with enticing
                      content, anchoring the highest probability of a
                      conversion. <br />
                      <br />
                      We help you create paid search campaigns from scratch or
                      pick-up your existing setup & optimize. To know more,
                      write to us.
                    </p>
                    <Link to="/contact/" className="btn main_cta">
                      Get in touch
                  </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="conpetulatization ">
            <div className="container">
              <div className="all-grows-block">
                <div className="ser_sec_row_lt  wdt-40">
                  <h2 className="heading_main">
                    Marketplace listing & optimization<br/>
                    <span className="highlight-span">Retrodays/allthatgrows</span>
                  </h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      With Amazon and eBay as the giants of the eCommerce marketplaces, it’s quite plausible that your brand might go unnoticed. 
                      It’s become inevitable for consumers to compare products across platforms to make the big decision of buying it. In this sea of competitors,
                       we ensure that your brand stands apart from the crowd, preventing any pitfalls that come with such colossal marketplaces while maintaining the 
                       organic performance of your eStore. 
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta">
                    Get in touch
                  </Link>
                </div>
                <div className="ser_sec_row_rt  wdt-60 hide-mob">
                  <img
                    src={Marketplace}
                    alt="Marketplace listing & optimization"
                    className="img-responsive"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className=" motion-graphics fleex remarketing">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_right_40">
                  <figure>
                    <img
                      src={Remarketing}
                      alt="Shopify Migration"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_left_40">
                  <h2 className="heading_main">remarketing</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      To think that all clicks will convert in the first
                      interaction is quite presumptuous. Ergo, to maintain a
                      socially-active identity of your business on the internet,
                      we devise the most optimal strategy to remarket your
                      products and services through Google & Facebook which
                      allows us to review, respond, and resolve every possible
                      query that your consumers might have, post their visit to
                      your website.
                      <br />
                      <br />
                      Reach out to us to understand how remarketing can help
                      your business grow.
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta">
                    get in touch
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <section className=" motion-graphics right-img fleex last_product ">
            <div className="container">
              <div className="print-outer">
                <div className="ser_sec_row_rt pd_left_20 right">
                  <figure>
                    <img
                      src={Ads}
                      alt="Comparison Shopping Engines"
                      className="img-responsive"
                    />
                  </figure>
                </div>
                <div className="ser_sec_row_lt pd_right_40">
                  <h2 className="heading_main">google shopping ads</h2>
                  <div className="ser_sec_row_lt_text">
                    <p>
                      While text ads have engaging content at their core, Google Shopping Ads include everything from the product name to corresponding reviews, 
                      giving the consumer all they need to know about a product to satisfy their purchase intent in one place. With our erudition of the Digital
                       Advertising world, we help your brand stand out amongst competitors by focusing on extensive keyword research, data-feed optimization,
                        product categorization as well as optimal bidding.
                        <br/><br/>
                        Want to discuss the Google Shopping Ads potential for your business. Write to us.
                    </p>
                  </div>
                  <Link to="/contact/" className="btn main_cta">
                   Contact us
                  </Link>
                </div>
              </div>
            </div>
          </section>

        </article>
      </Layout>
    )
  }
}
